import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import ForgotPasswordForm from 'src/sections/Auth/ForgotPassword/Form';
import ApiWrapper from 'src/components/ApiWrapper';

const ForgotPasswordPage: React.FC = () => (
  <ApiWrapper apiServices={['auth-api', 'home-api']}>
    <Layout>
      <Seo title="Forgot Password - Bizwise" />
      <ForgotPasswordForm />
    </Layout>
  </ApiWrapper>
);

export default ForgotPasswordPage;
