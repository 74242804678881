import { useState } from 'react';
import { useAlert } from 'src/alert';
import emailValidator from 'email-validator';
import { useLink } from 'src/hooks';

function validate(email: string): string | null {
  if (email.length === 0) {
    return 'This is required!';
  }
  if (!emailValidator.validate(email.trim())) {
    return 'Please enter a valid email address';
  }
  return null;
}

export default function useForgotPassword() {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showInputError, setShowInputError] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const alert = useAlert();
  const { onNavigate: onSuccessNavigate } = useLink('/forgot_password/success', `?email=${encodeURIComponent(email)}`);

  const handleEmailChange = (newEmail: string) => {
    setServerError(null);
    setEmail(newEmail);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const hasError = validate(email);
    if (hasError) {
      setShowInputError(true);
      return;
    }

    try {
      setSubmitting(true);
      await window.apis['auth-api'].auth.forgotPassword({
        email: {
          email: email.trim(),
        },
      });
      onSuccessNavigate();
    } catch (err) {
      // @ts-expect-error This is valid
      if (err.statusCode === 404) {
        alert.error('There is no account registered with this email!');
      } else {
        alert.error('Something went wrong! Please try again in a few minutes.');
      }
    }
    setSubmitting(false);
  };

  const inputError = showInputError && validate(email);

  return {
    email,
    handleEmailChange,
    submitting,
    inputError,
    serverError,
    handleSubmit,
  };
}
