import React from 'react';
import { MailIcon } from '@heroicons/react/outline';
import Input from 'src/components/core/Input';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { spacing } from 'src/theme';
import SectionContainer from '../../shared/SectionContainer';
import AuthBackground from '../Background';
import SignUpPrompt from '../SignUpPrompt';
import useForgotPassword from './utils';

const ForgotPasswordForm: React.FC = () => {
  const {
    email,
    handleEmailChange,
    submitting,
    inputError,
    serverError,
    handleSubmit,
  } = useForgotPassword();

  return (
    <SectionContainer background="light-blue" style={{ padding: 0 }}>
      <AuthBackground>
        <div className="pt-8 pb-14 px-8 sm:px-16 sm:pt-14">
          <Typography
            variant="h3"
            color="blue"
            className="mb-2"
            style={{ fontWeight: 700 }}
          >
            Forgot Password?
          </Typography>
          <Typography
            style={{
              marginBottom: spacing(7),
            }}
            variant="p3"
            color="textSecondary"
          >
            In order for us to send you a reset password link, please fill in your email address
            {' '}
            that&apos;s associated to your Bizwise account below.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Input
              label="Email Address"
              labelIcon={MailIcon}
              name="email"
              value={email}
              error={inputError || serverError}
              onChange={handleEmailChange}
              inputProps={{
                autoComplete: 'email-address',
                placeholder: 'you@example.com',
              }}
            />
            <Button
              className="mt-5 mb-3 w-full"
              type="submit"
              variant="filled"
              color="teal"
              disabled={submitting}
            >
              Submit
            </Button>
            <SignUpPrompt />
          </form>
        </div>
      </AuthBackground>
    </SectionContainer>
  );
};

export default ForgotPasswordForm;
